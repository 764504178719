//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import supplierSign from "@/lib/data-service/default/web_esIgn_esIgnSignerController_supplierSign";
import buyerSign from "@/lib/data-service/default/web_esIgn_esIgnSignerController_buyerSign";
import supplierSignAcc from "@/lib/data-service/default/web_esIgn_esIgnSignerController_supplierSignAcc";
import buyerAcc from "@/lib/data-service/default/web_esIgn_esIgnSignerController_buyerAcc";

export default {
  data() {
    return {
      account: "",
      img: [
        "//yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/default-v2/distributor/registration%20success.png?x-oss-process=style/webp",
        "//yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/default-v2/distributor/audit.png?x-oss-process=style/webp",
        "//yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/default-v2/distributor/error.png?x-oss-process=style/webp",
        "//yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/default-v2/distributor/sign.png?x-oss-process=style/webp"
      ],
      //0 = 提交成功，1 = 审核中， 2 = 签约失败，3 = 审核成功
      type: 0,
      auditStatu: false,
      audit: false,
      btnText: "返回首页",
      accountType: 1, //1 2 供应商 ， 3 4 分销商 5 平台 6游客 7个人分销
      pdfUrl: "",
      time: 10
    };
  },
  methods: {





  },

};
